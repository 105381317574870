
import { defineComponent, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import { useStore } from "vuex";
import TableExplorer from "@/components/TableExplorer.vue";
import JerrycanFilters from "@/components/JerrycanFilters.vue";

export default defineComponent({
  name: "kt-widget-12",
  components: { JerrycanFilters, TableExplorer },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      filters: {
        string: "",
        dateBetween: [],
      },
      perPage: 10,
      entities: null,
      lastpage: 1,
      values: {},
      stringFilter: "",
      page: 1,
      pages: [],
      count: null,
    };
  },
  methods: {
    saveValues(e) {
      ApiService.post("/samples/batch", { values: this.values })
        .then((response) => {
          (this.$refs.dataTable as any).retrieveData();
        })
        .catch((error) => {
          alert("lolo");
        });
    },
  },

  mounted() {
    watch(this.filters, () => {
      (this.$refs.dataTable as any).retrieveData();
    });
  },

  setup() {
    const store = useStore();
    return { store };
  },
});
